<template>
  <div class="page">
    <div class="header" id="navbar">
      <div class="navbar navbar-mobile d-flex justify-content-between d-md-none">
        <a href="/#/"><img class="logo d-md-none" alt="Logo" title="Logo" src="@/assets/pocketyard_logo.svg" /></a>
        <a href="/#/"><img class="logo d-none d-md-inline" alt="Logo" title="Logo" src="@/assets/pocketyard_logo_black.svg" /></a>
        <img class="hamburger" src="@/assets/Landing/hamburger.svg" @click="isSidebarOpen = true" />
      </div>
      <div class="navbar d-none d-md-flex">
        <a href="/#/"><img class="logo" alt="Logo" title="Logo" src="@/assets/pocketyard_logo_black.svg" /></a>
        <div class="buttons">
          <button class="btn login mr-3" @click="$router.push('login')">LOGIN</button>
          <button class="btn signup" @click="$router.push('register')">SIGN UP</button>
        </div>
      </div>
      <LandingSidebarVue :isOpen="isSidebarOpen" @close="isSidebarOpen = false" activeItem="vendors"/>
      <div class="hero">
        <div class="title">
          <div class="d-flex justify-content-start justify-content-md-center">
            <img class="icon-vendor" src="@/assets/Landing/vendor-icon.svg"/><h1 class="main">For Vendors</h1>
          </div>
          <p class="sub">Simplification is key</p>
        </div>
        <div class="tagline">
          <h2 class="main">Communicate. Sell. Manage.</h2>
          <p class="sub d-none d-lg-block">Pocketyard offers you cost and time-effective solutions to sell materials,<br />equipment, tools and safety equipment.</p>
          <p class="sub d-lg-none">Pocketyard offers you cost and time-effective solutions to sell materials, equipment, tools and safety equipment.</p>
        </div>
        <div class="button">
          <button class="btn buyer-button" @click="$router.push('buyer')">Buyers <img src="@/assets/Landing/right-arrow.svg" /></button>
        </div>
      </div>
      <div class="offset-container">
        <img src="@/assets/Landing/vendor-dashboard.png" class="offset-image">
      </div>
    </div>

    <div class="body">
      <div class="list d-flex flex-column">
        <div class="item d-flex flex-column flex-md-row">
          <div class="texts">
            <h6 class="small-title">Effortless</h6>
            <h3 class="title">Effortless Business Management</h3>
            <p class="description">Experience a new level of efficiency with pocketyard. Our platform provides all the essential tools to track quotation requests, monitor approvals, and manage your transactions seamlessly.</p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Always updated, helps to make timely decisions and never miss an opportunity.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Managing construction materials business becomes a breeze.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/vendor-item-1.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/vendor-item-1-mobile.png" class="item-image d-md-none">
          </div>
        </div>
        <div class="item d-flex flex-column flex-md-row-reverse">
          <div class="texts texts-right">
            <h6 class="small-title">Easy</h6>
            <h3 class="title">Easily List Your Products</h3>
            <p class="description">Listing your products is as simple as a few clicks.</p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>User-friendly interface to manage product listings effectively and update inventory in real-time.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Intelligent categorization and search features for potential buyers to find exactly what they need, increasing sales opportunities.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/vendor-item-2.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/vendor-item-2-mobile.png" class="item-image d-md-none">
          </div>
        </div>
        <div class="item d-flex flex-column flex-md-row">
          <div class="texts">
            <h6 class="small-title">Communication</h6>
            <h3 class="title">Streamlined Communication</h3>
            <p class="description">Say goodbye to missed calls and cluttered inboxes. Pocketyard provides a platform for you to stay connected with your clients like never before.</p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Send updates, answer queries, and manage orders seamlessly within one platform.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Keeps a clear and accessible record of all the transactions and communication, making organization and efficiency easier than ever.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/vendor-item-3.png" class="item-image image-with-shadow">
          </div>
        </div>
        <div class="item d-flex flex-column flex-md-row-reverse">
          <div class="texts texts-right">
            <h6 class="small-title">Build</h6>
            <h3 class="title">Build Your Reputation</h3>
            <p class="description">Our platform provides an opportunity for you to demonstrate your commitment to quality and service. By listing your products on pocketyard, you're not just selling - you're establishing a reputation among a diverse and expansive clientele.</p>
            <div class="bullets">
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Construction materials business finds its perfect home.</p>
              </div>
              <div class="bullet d-flex align-items-center">
                <img src="@/assets/Landing/ic-plus.png" class="plus-icon"/>
                <p>Sign up now and start your journey to success with pocketyard.</p>
              </div>
            </div>
          </div>
          <div class="image-container">
            <img src="@/assets/Landing/vendor-item-4.png" class="item-image d-none d-md-block">
            <img src="@/assets/Landing/vendor-item-4-mobile.png" class="item-image d-md-none">
          </div>
        </div>
      </div>
    </div>
    <LandingFooter activeItem="vendors" />
  </div>
</template>

<script>
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import LandingSidebarVue from '@/shared/elements/LandingSidebar.vue';
import LandingFooter from '@/shared/elements/LandingFooter.vue';

export default {
  name: 'Landing',

  mixins: [_appApiHelper],

  components: {
    LandingSidebarVue,
    LandingFooter,
  },

  data() {
    return {
      isSidebarOpen: false,
    }
  },

  mounted() {
    this.scrollToTop()
  },
  methods: {
    scrollToTop() {
      document.getElementById('navbar').scrollIntoView({ behavior: 'smooth' })
    },
    scrollToDetails() {
      document.getElementById('details').scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
@import '@/scss/custom/vendor';
</style>